import { captureException } from '@sentry/vue'

export function useIssuerThemeAbTest() {
  const isTestEnabled = useState<boolean>('isTestEnabled', () => false)

  try {
    // get from session storage
    const testEnabled = sessionStorage.getItem('issuerThemeTestEnabled')

    if (testEnabled) {
      isTestEnabled.value = !!testEnabled
    }
  }
  catch (err) {
    captureException(err)
    // nothing to do
  }

  return {
    isTestEnabled,
  }
}
