import { default as contacts24rVhTtfNVMeta } from "/home/runner/work/james-order-form-app/james-order-form-app/pages/contacts.vue?macro=true";
import { default as dokumentyB9f2JLSG4MMeta } from "/home/runner/work/james-order-form-app/james-order-form-app/pages/dokumenty.vue?macro=true";
import { default as indexFgQv3cdF54Meta } from "/home/runner/work/james-order-form-app/james-order-form-app/pages/index.vue?macro=true";
import { default as investor93G2fEG6O4Meta } from "/home/runner/work/james-order-form-app/james-order-form-app/pages/investor.vue?macro=true";
import { default as resultMlEdSxC8sMMeta } from "/home/runner/work/james-order-form-app/james-order-form-app/pages/result.vue?macro=true";
export default [
  {
    name: contacts24rVhTtfNVMeta?.name ?? "contacts",
    path: contacts24rVhTtfNVMeta?.path ?? "/contacts",
    meta: contacts24rVhTtfNVMeta || {},
    alias: contacts24rVhTtfNVMeta?.alias || [],
    redirect: contacts24rVhTtfNVMeta?.redirect,
    component: () => import("/home/runner/work/james-order-form-app/james-order-form-app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: dokumentyB9f2JLSG4MMeta?.name ?? "dokumenty",
    path: dokumentyB9f2JLSG4MMeta?.path ?? "/dokumenty",
    meta: dokumentyB9f2JLSG4MMeta || {},
    alias: dokumentyB9f2JLSG4MMeta?.alias || [],
    redirect: dokumentyB9f2JLSG4MMeta?.redirect,
    component: () => import("/home/runner/work/james-order-form-app/james-order-form-app/pages/dokumenty.vue").then(m => m.default || m)
  },
  {
    name: indexFgQv3cdF54Meta?.name ?? "index",
    path: indexFgQv3cdF54Meta?.path ?? "/",
    meta: indexFgQv3cdF54Meta || {},
    alias: indexFgQv3cdF54Meta?.alias || [],
    redirect: indexFgQv3cdF54Meta?.redirect,
    component: () => import("/home/runner/work/james-order-form-app/james-order-form-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: investor93G2fEG6O4Meta?.name ?? "investor",
    path: investor93G2fEG6O4Meta?.path ?? "/investor",
    meta: investor93G2fEG6O4Meta || {},
    alias: investor93G2fEG6O4Meta?.alias || [],
    redirect: investor93G2fEG6O4Meta?.redirect,
    component: () => import("/home/runner/work/james-order-form-app/james-order-form-app/pages/investor.vue").then(m => m.default || m)
  },
  {
    name: resultMlEdSxC8sMMeta?.name ?? "result",
    path: resultMlEdSxC8sMMeta?.path ?? "/result",
    meta: resultMlEdSxC8sMMeta || {},
    alias: resultMlEdSxC8sMMeta?.alias || [],
    redirect: resultMlEdSxC8sMMeta?.redirect,
    component: () => import("/home/runner/work/james-order-form-app/james-order-form-app/pages/result.vue").then(m => m.default || m)
  }
]