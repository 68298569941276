/* tslint:disable */
/* eslint-disable */
/**
 * Bonds API
 * Microservice backend for bond management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DetailQuestion
 */
export interface DetailQuestion {
    /**
     * 
     * @type {string}
     * @memberof DetailQuestion
     */
    type: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof DetailQuestion
     */
    data: { [key: string]: any; };
}

/**
 * Check if a given object implements the DetailQuestion interface.
 */
export function instanceOfDetailQuestion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function DetailQuestionFromJSON(json: any): DetailQuestion {
    return DetailQuestionFromJSONTyped(json, false);
}

export function DetailQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'data': json['data'],
    };
}

export function DetailQuestionToJSON(value?: DetailQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'data': value.data,
    };
}

