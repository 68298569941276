import { useStepper } from '~/composables/stepper/useStepper'

export default defineNuxtRouteMiddleware(async (to) => {
  const {
    step,
    validateStep,
    initSteps,
    getStepByRoutePath,
    setStep,
  } = useStepper()

  initSteps()

  if (!validateStep(to.path)) {
    return abortNavigation()
  }

  const targetStep = await getStepByRoutePath(to.path)

  if (targetStep !== -1) {
    if (step.value === targetStep)
      return null
    await setStep(targetStep, true)
  }

  return null
})
