import { pick } from 'lodash-es'
import type { TrackingData } from '~/composables/tracking/types'
import { TrackingKeys } from '~/composables/tracking/types'

export function useTracking() {
  const trackingData = useState<Partial<TrackingData> | null>('trackingData', () => null)

  return {
    trackingData: readonly(trackingData),

    setTrackingData(data: Partial<TrackingData>) {
      trackingData.value = {
        ...trackingData.value,
        ...data,
      }
    },

    init() {
      const query = pick(useRoute().query, Object.values(TrackingKeys))

      // Replace null with empty object to mark that tracking data was initialized
      if (!trackingData.value) {
        trackingData.value = {} as TrackingData
      }

      Object.values(TrackingKeys).forEach((key) => {
        trackingData.value![key] = query[key] as TrackingData[TrackingKeys] || null
      })
    },
  }
}
