import { colorsByIssuer } from '~/constants/emitentColots'

export function useCompanyTheme(company: { companyId: string }) {
  const companyInList = colorsByIssuer.find(({ companyId }) => companyId === company.companyId)

  return {
    enable(): boolean {
      if (companyInList) {
        const {
          primary,
          secondary,
        } = companyInList
        const primaryColor = secondary || primary
        const primaryColorRgb = hexToRgb(primaryColor)

        const styleElement = document.createElement('style')

        styleElement.innerHTML = `
            :root {
              --cfg-brand: ${primaryColor};
            }

            :root .cfg-slider {
              --slider-connect-bg: rgba(${primaryColorRgb}, 0.5);
              --slider-bg: var(--cfg-grey-stroke);
            }

            .bg-brand {
              background: ${primaryColor} !important;
            }

            .bg-brand\\/10 {
              background: rgba(${primaryColorRgb}, 0.1) !important;
            }

            .text-brand {
              color: ${primaryColor} !important;
            }

            .\\!text-brand {
              color: ${primaryColor} !important;
            }

            .border-brand {
              --tw-border-opacity: 1;
              border-color: ${primaryColor} !important;
            }

            .prose :where(a):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
                color: ${primaryColor} !important;
            }

            .lg\\:hover\\:text-brand:hover {
              color: ${primaryColor} !important;
            }

            .lg\\:hover\\:border-brand:hover {
              border-color: ${primaryColor} !important;
            }

            .page-background {
              background: none;
            }

            .styled-box {
              border: 1px solid var(--cfg-grey-stroke);
            }

            .header-stepper-bg {
              background: var(--cfg-grey-50) !important
            }

            footer {
              background: var(--cfg-grey-50) !important;
            }

            .referrer-infobox {
              background: var(--cfg-grey-50) !important;
            }

            .referrer-infobox svg {
              color: var(--cfg-grey-300) !important;
            }
        `

        document.body.appendChild(styleElement)

        return true
      }

      return false
    },
  }
}

function hexToRgb(hex: string): string {
  const bigint = Number.parseInt(hex.slice(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return `${r},${g},${b}`
}
