export enum TrackingKeys {
  a_box = 'a_box',
  a_cam = 'a_cam',

  utm_id = 'utm_id',
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
  utm_term = 'utm_term',
  utm_content = 'utm_content',

  ga_id = 'ga_id',
  ga_session_id = 'ga_session_id',
  ext_id = 'ext_id',

  url = 'url',
}

export type TrackingData = Record<TrackingKeys, string | null>
