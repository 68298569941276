import type { BonusTypeEnum } from '~/services/generated/client'
import type { InvestorContactData, InvestorData } from '~/composables/investor/types'
import type { TrackingData } from '~/composables/tracking/types'

export type OrderData = {
  amount: number | null
  mediator: string | null
  referrer: string | null
  issueId: number | null
  bonus: number | null
  bonusType: BonusTypeEnum | null
}
export type FullOrder = OrderData & InvestorData & InvestorContactData & Partial<TrackingData>

export type GetFormResult = {
  data: Partial<FullOrder>
  state: string
  token: string
  type: string
}

export enum OrderStatesEnum {
  basicInfo = 'BASIC_INFO',
  investorInfo = 'INVESTOR_INFO',
}
