import type { Address } from '~/services/generated/client'

export type InvestorData = {
  name: string | null
  companyId: string | null
  companyAddress: Address | null
  representativeName: string | null
  representativeAddress: Address | null
  birthDate: Date | null
  birthNumber: string | null
  residenceAddress: Address | null
  mailAddress: Address | null
  bankAccount: string | null
  variableSymbolInterest: string | null
  specificSymbolInterest: string | null
  variableSymbolPrincipal: string | null
  specificSymbolPrincipal: string | null
  note: string | null
  legalForm: PersonTypesEnum
}

export type InvestorContactData = {
  name: string | null
  email: string | null
  phone: string | null
  gdprConsent: Date | null
}

export enum PersonTypesEnum {
  Person = 'PHYSICAL_ENTITY',
  Company = 'LEGAL_ENTITY',
}
