<script setup lang="ts">
</script>

<template>
  <div class="page-background relative">
    <div class="absolute top-0 hidden h-full w-full lg:block">
      <picture>
        <source media="(max-width: 992px)" srcset="">

        <img
          alt="Decor"
          aria-hidden="true"
          class="absolute left-0 top-[10%] h-auto w-[200px] xl:w-[250px]"
          loading="lazy"
          src="/img/decoration-left.png"
        >
      </picture>

      <picture>
        <source media="(max-width: 768px)" srcset="">

        <img
          alt="Decor"
          aria-hidden="true"
          class="absolute bottom-[10%] right-0 h-auto w-[200px] xl:w-[250px]"
          loading="lazy"
          src="/img/decoration-right.png"
        >
      </picture>
    </div>

    <div class="relative flex w-full flex-1 flex-col">
      <slot />
    </div>
  </div>
</template>

<style>
.page-background {
  background: #E5EBF9 url("/img/bg.png") no-repeat center center / cover;
}
</style>
