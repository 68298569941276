import { captureException } from '@sentry/vue'
import { useIssuerThemeAbTest } from './abTest/useIssuerThemeAbTest'
import { useTracking } from '~/composables/tracking/useTracking'
import { useIssue } from '~/composables/issue/useIssue'
import { useOrder } from '~/composables/order/useOrder'
import { useStepper } from '~/composables/stepper/useStepper'
import { BonusTypeEnum } from '~/services/generated/client'
import { ErrorTypesEnum } from '~/composables/error/types'
import { DEFAULT_INVESTMENT_AMOUNT } from '~/constants'
import { bonus } from '~/constants/bonus'
import { parsePhone } from '~/helpers/parsePhone'
import { BUFFLER_PHONE } from '~/constants/buffler'

type QueryData = {
  mediator: string
  issueId: string
  token: string
  amount: string
  src?: string
}

export enum SourceQuery {
  dluhopisomat = '1',
  srov = '2',
}

export type AppData = {
  company: {
    name: string
    logo: string
    address: string
    phone: string
  }
}

export function useApp() {
  const { app } = useRuntimeConfig()

  const appData = useState<AppData>('appData', () => ({
    company: {
      name: '',
      address: '',
      logo: '',
      phone: '',
    },
  }))

  const {
    issue,
    fetchIssue,
  } = useIssue()

  const {
    order,
    orderState,
    orderToken,
    setPartialOrder,
    init: initOrder,
    setOrderToken,
  } = useOrder()

  const loading = useState<boolean>('loading', () => false)

  const route = useRoute()

  const { init: initTracking } = useTracking()
  const { init: initStepper } = useStepper()

  const contactPhone = computed(() => {
    if (order.value?.mediator === 'buffler') {
      return parsePhone(BUFFLER_PHONE)
    }

    return order.value?.mediator
      ? parsePhone(app.contact.phone)
      : parsePhone(appData.value.company.phone || app.contact.phone)
  })

  watch(issue, (data) => {
    if (!data) {
      return
    }

    const {
      company: {
        name,
        address,
        logo,
        phone,
      },
    } = data

    appData.value.company = {
      name,
      logo,
      address: address.firstLine || '',
      phone,
    }
  }, { immediate: true })

  function setLoading(value: boolean) {
    loading.value = value
  }

  return {
    /**
     * @description Global data to be used in different parts of the app
     */
    appData: readonly(appData),

    /**
     * @description Add loading state;
     */
    loading: readonly(loading),

    contactPhone,

    setLoading,

    /**
     * @description Initialize app. Must be called on app init ONCE!
     */
    async init(referrer?: string) {
      setLoading(true)

      const {
        mediator = null,
        issueId,
        token,
        amount = DEFAULT_INVESTMENT_AMOUNT,
      } = route.query as Partial<QueryData>

      // Grab utm params from url
      initTracking()

      // Set token from url
      await setOrderToken(token || null)

      // Fetch order data if token is set
      try {
        await initOrder({
          ...(orderToken.value ? { token: orderToken.value } : {}),
        })
      }
      catch (err) {
        if ((err as Error).message === ErrorTypesEnum.wrongToken) {
          return showError(ErrorTypesEnum.wrongToken)
        }
        else {
          captureException(err)
        }
      }

      // stepper must be initialized after order is initialized to remove incorrect token before route validation
      await initStepper(orderToken.value, orderState.value)

      try {
        const resultedIssueId = issueId ? Number(issueId) : order.value.issueId

        // Exit if issueId is not set
        if (!resultedIssueId) {
          return showError(ErrorTypesEnum.issueIdMissing)
        }

        const fetchedIssue = await fetchIssue(resultedIssueId)

        if (fetchedIssue && useIssuerThemeAbTest().isTestEnabled.value) {
          useCompanyTheme({ companyId: fetchedIssue.company.companyId })
            .enable()
        }

        setPartialOrder({
          issueId: issueId ? Number(issueId) : order.value.issueId,
          amount: order.value.amount || Number(amount),
          referrer: referrer || order.value.referrer,
          mediator: mediator || order.value.mediator,
          ...(fetchedIssue?.promotionBonus || bonus.some(b => b.issueId === Number(issueId))) && {
            bonusType: BonusTypeEnum.EveryPayment,
            bonus: fetchedIssue?.promotionBonus || bonus.find(b => b.issueId === Number(issueId))?.bonusPercentage,
          },
        })
      }
      catch (_) {
        showError(ErrorTypesEnum.issueNotFound)
      }
      finally {
        setLoading(false)
      }

      return null
    },
  }
}

function showError(error: ErrorTypesEnum, code = 404) {
  throw createError({
    statusCode: code,
    message: error,
  })
}
