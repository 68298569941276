import { PHONE_PREFIX } from '~/constants'

/**
 * Parse phone number
 * @param phone Phone number without spaces
 * @returns object with original and formatted phone
 */
export function parsePhone(phone: string) {
  const phoneHasPrefix = phone.trim().startsWith(PHONE_PREFIX)

  const phoneWithPrefix = (phoneHasPrefix ? phone : PHONE_PREFIX + phone).replace(/ /g, '')

  return {
    original: phoneWithPrefix,
    formatted: phoneWithPrefix.replace(/(\d{3})/g, '$1 ').trim(), // Add non-breaking spaces after each 3 digits
  }
}
