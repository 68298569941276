/* tslint:disable */
/* eslint-disable */
/**
 * Bonds API
 * Microservice backend for bond management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BonusTypeEnum = {
    FirstPayment: 'FIRST_PAYMENT',
    LastPayment: 'LAST_PAYMENT',
    EveryPayment: 'EVERY_PAYMENT'
} as const;
export type BonusTypeEnum = typeof BonusTypeEnum[keyof typeof BonusTypeEnum];


export function BonusTypeEnumFromJSON(json: any): BonusTypeEnum {
    return BonusTypeEnumFromJSONTyped(json, false);
}

export function BonusTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BonusTypeEnum {
    return json as BonusTypeEnum;
}

export function BonusTypeEnumToJSON(value?: BonusTypeEnum | null): any {
    return value as any;
}

