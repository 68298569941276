import { OrderStatesEnum } from '~/composables/order/types'

export enum RouteNamesEnum {
  home = 'home',
  contacts = 'contacts',
  investor = 'investor',
  result = 'result',
  documents = 'documents',
}

export function useStepper() {
  const routesList = [
    {
      path: '/',
      name: RouteNamesEnum.home,
      tokenRequired: false,
      stepper: true,
    },
    {
      path: '/contacts',
      stepper: true,
      name: RouteNamesEnum.contacts,
      tokenRequired: false,
    },
    {
      path: '/investor',
      stepper: true,
      name: RouteNamesEnum.investor,
      tokenRequired: true,
      orderState: OrderStatesEnum.basicInfo,
    },
    {
      path: '/result',
      stepper: true,
      name: RouteNamesEnum.result,
      tokenRequired: true,
      orderState: OrderStatesEnum.investorInfo,

    },
    {
      path: '/dokumenty',
      stepper: false,
      name: RouteNamesEnum.documents,
      tokenRequired: true,
    },
  ]

  const stepperRoutes = routesList.filter(({ stepper }) => stepper)

  const step = useState<number>('step')
  const initialized = useState<boolean>('initializedStep')

  async function setRouteByStep(byStep: number) {
    const route = useRoute()

    const foundRoute = stepperRoutes[byStep]

    if (!foundRoute || getNormalizedPath(route.path) === foundRoute.path)
      return

    await navigateTo({ path: foundRoute.path, query: route.query }, {
      redirectCode: 301,
    })
  }

  async function setStep(newStep: number, silent = false) {
    step.value = newStep

    if (silent)
      return

    await nextTick(() => setRouteByStep(step.value))
  }

  async function nextStep() {
    await setStep(step.value + 1)
  }

  async function setRouteByOrderState(state: OrderStatesEnum) {
    await setStep(stepperRoutes.findIndex(({ orderState }) => orderState === state))
  }

  async function getStepByRoutePath(path: string) {
    return stepperRoutes.findIndex(({ path: routePath }) => routePath === getNormalizedPath(path))
  }

  function validateRoute(path: string, token: string | null) {
    const isStepValid = validateStep(path)
    const foundRoute = routesList.find(routeItem => routeItem.path === getNormalizedPath(path))

    if (!isStepValid) {
      // Always allow to go to steps that are not in the stepper;
      return foundRoute?.stepper === false
    }

    if (foundRoute?.tokenRequired) {
      return !!token
    }

    return isStepValid
  }

  function validateStep(path: string): boolean {
    const targetIndex = stepperRoutes.findIndex(routeItem => routeItem.path === getNormalizedPath(path))

    const isBack = targetIndex < step.value

    // Allow to go forward only if page is current step.
    // Allow to go back only if the step is not the last one
    return targetIndex === step.value || (isBack && step.value !== stepperRoutes.length - 1)
  }

  function initSteps(initialPath?: string) {
    if (!initialized.value) {
      const route = useRoute()

      const path = initialPath || route.path

      step.value = routesList.findIndex(routeItem => routeItem.path === getNormalizedPath(path))

      initialized.value = true
    }
  }

  return {
    initialized,
    step: readonly(step),

    async init(token: string | null, orderState?: OrderStatesEnum | null) {
      const router = useRouter()
      initSteps(router.currentRoute.value.path)

      if (!validateRoute(router.currentRoute.value.path, token)) {
        await navigateTo({ path: routesList[0].path, query: router.currentRoute.value.query })
      }
      else if (orderState) {
        const currentRoute = routesList.find(({ path }) => path === getNormalizedPath(router.currentRoute.value.path))

        if (currentRoute?.stepper === true) {
          await setRouteByOrderState(orderState)
        }
      }
    },

    initSteps,

    nextStep,

    setStep,

    validateRoute,
    validateStep,
    getStepByRoutePath,
  }
}

function getNormalizedPath(path: string) {
  if (path === '/')
    return path
  return path[path.length - 1] === '/' ? path.slice(0, -1) : path
}
