<script setup lang="ts">
import { CfgApplicationError } from '@cfgtech/ui'
import type { NuxtError } from '#app'
import { useHandleError } from '~/composables/error'
import type { ErrorTypesEnum } from '~/composables/error/types'
import { useIssue } from '~/composables/issue/useIssue'

const props = defineProps<{
  error: NuxtError
}>()

const { t } = useI18n()
const { parseError } = useHandleError()

const { issue } = useIssue()
const { contactPhone } = useApp()

const messages = computed(() => {
  const message = props.error.message as ErrorTypesEnum

  const { title, description } = parseError(message)

  const company = issue.value?.company

  const email = company?.email || ''
  const isPhone = company?.phone || !email

  return {
    title: t(title),
    description: t(description, {
      contact: `
        <a href="${isPhone ? `tel:${contactPhone.value.original}` : `mailto:${email}`}" class="text-brand underline">
          ${isPhone ? contactPhone.value.formatted : email}
        </a>
      `,
    }),
  }
})

watch(messages, (data) => {
  useHead({
    title: data?.title || 'Chyba',
  })
}, { immediate: true, deep: true })
</script>

<template>
  <NuxtErrorBoundary>
    <UiPageBackground class="flex items-center justify-center">
      <div class="w-full">
        <CfgApplicationError
          button-label=""
          :description="messages.description"
          header=""
          :status-code="error?.statusCode || ''"
          :title="messages.title"
        />
      </div>
    </UiPageBackground>
  </NuxtErrorBoundary>
</template>
