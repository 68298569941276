export const colorsByIssuer = [
  {
    companyId: '19919280',
    name: 'S-24 Finance',
    primary: '#009DE0',
    secondary: null,
  },
  {
    companyId: '09278788',
    name: 'Orka Project Financing',
    primary: '#C4E4F5',
    secondary: '#014466',
  },
  {
    companyId: '24807842',
    name: 'SMS Vysledky',
    primary: '#DAFF00',
    secondary: '#AABA09',
  },
  {
    companyId: '14432277',
    name: 'Detalon Realty',
    primary: '#8E5F0B',
    secondary: null,
  },
  {
    companyId: '17464986',
    name: 'Forinvent',
    primary: '#01A0E3',
    secondary: null,
  },
  {
    companyId: '27353737',
    name: 'Buffler',
    primary: '#ED1C24',
    secondary: null,
  },
]
