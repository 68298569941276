import { toCamelCase } from '@cfgtech/helpers'
import { isEqual, omit, pick } from 'lodash-es'
import { captureException } from '@sentry/vue'
import type { FullOrder, GetFormResult, OrderStatesEnum } from '~/composables/order/types'
import { PersonTypesEnum } from '~/composables/investor/types'
import { FormsApi } from '~/services/generated/client'
import { useHandleError } from '~/composables/error'
import { ErrorTypesEnum } from '~/composables/error/types'
import { useTracking } from '~/composables/tracking/useTracking'
import { TrackingKeys } from '~/composables/tracking/types'

const initialOrder = {
  legalForm: PersonTypesEnum.Person,
  issueId: null,
  amount: null,

  mediator: null,
  referrer: null,

  name: null,
  email: null,
  phone: null,
  gdprConsent: null,

  companyAddress: null,
  companyId: null,

  residenceAddress: null,
  mailAddress: null,

  representativeAddress: null,
  representativeName: null,

  bankAccount: null,

  birthDate: null,
  birthNumber: null,

  bonus: null,
  bonusType: null,

  note: null,
} satisfies FullOrder

export type UseOrderPayload = {
  token?: string
}

export function useOrder() {
  const order = useState<FullOrder>('order', () => ({ ...initialOrder }))
  const orderState = useState<OrderStatesEnum | null>('state', () => null)
  const orderToken = useState<string | null>('token', () => null)

  const router = useRouter()

  function setPartialOrder(payload: Partial<FullOrder>) {
    order.value = {
      ...order.value,
      ...payload,
    }
  }

  async function fetchOrder() {
    try {
      if (!orderToken.value) {
        return null
      }

      return await new FormsApi(useApiConfiguration()).getForm({ token: orderToken.value }) as GetFormResult
    }
    catch (err) {
      captureException(err)
      return null
    }
  }

  return {
    order: readonly(order),
    orderState: readonly(orderState),
    orderToken: readonly(orderToken),

    setOrderState(state: OrderStatesEnum) {
      orderState.value = state
    },

    async setOrderToken(token: string | null) {
      orderToken.value = token

      const targetQuery = token ? { ...router.currentRoute.value.query, token } : omit(router.currentRoute.value.query, ['token'])

      if (isEqual(router.currentRoute.value.query, targetQuery)) {
        return
      }

      await navigateTo({
        path: router.currentRoute.value.path,
        query: token ? { ...router.currentRoute.value.query, token } : omit(router.currentRoute.value.query, ['token']),
      }, {
        replace: true,
      })
    },

    setPartialOrder,

    async init(payload: UseOrderPayload) {
      order.value = { ...initialOrder }

      if (payload.token) {
        const currentOrder = await fetchOrder()

        const { setTrackingData } = useTracking()

        const { createError } = useHandleError()

        if (!currentOrder) {
          throw new Error(createError(ErrorTypesEnum.wrongToken))
        }

        orderState.value = currentOrder.state as OrderStatesEnum

        setTrackingData(
          pick(currentOrder.data, Object.values(TrackingKeys)),
        )

        order.value = {
          ...order.value,
          ...toCamelCase(
            omit(currentOrder.data, Object.values(TrackingKeys)),
          ),
        }
      }

      return order.value
    },
  }
}
