import { wait } from '~/helpers/wait'

const WAIT_TO_NEXT_TRY_MS = 50
const MAX_TRY_COUNT = Array.from({ length: 5 }).fill(null).map((_, i) => i)

export default defineNuxtPlugin({
  parallel: true,

  async setup() {
    let isSpriteLoaded = false

    let lastError: unknown | null = null

    for await (const i of MAX_TRY_COUNT) {
      try {
        if (isSpriteLoaded) {
          break
        }

        if (i > 0) {
          await wait(WAIT_TO_NEXT_TRY_MS)
        }
        const path = '/sprite.svg'
        const sprite = await useFetch<Blob>(path)

        if (sprite.data.value) {
          const reader = new FileReader()

          reader.readAsText(sprite.data.value)
          reader.onload = (e) => {
            isSpriteLoaded = true

            const el = document.createElement('div')
            el.classList.add('sprite-container')
            el.innerHTML = (e.target!).result as string
            document.body.appendChild(el)
          }
        }
      }
      catch (err) {
        lastError = err
      }
    }

    // CAPTURE EXCEPTION IF SVG SPRITE WAS NOT LOADED AFTER MAX TRY COUNT
    if (!isSpriteLoaded) {
      const { captureException } = await import('@sentry/core')
      let errorString = 'Unknown error'

      try {
        if (lastError) {
          errorString = JSON.stringify(lastError)
        }
      }
      catch (_) {
        // Nothing to do
      }

      captureException(new Error(`SVG sprite was not loaded. Last captured error: ${errorString}`))
    }
  },
})
