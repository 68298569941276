import { ErrorTypesEnum } from './types'

export function useHandleError() {
  function validateErrorType(error: ErrorTypesEnum | string) {
    return Object.values(ErrorTypesEnum).includes(error as ErrorTypesEnum) ? error : ErrorTypesEnum.unknownError as ErrorTypesEnum
  }

  return {
    parseError(error: ErrorTypesEnum | string) {
      const validatedError = validateErrorType(error)

      return {
        error,
        title: `error.${validatedError}.title`,
        description: `error.${validatedError}.description`,
      }
    },

    createError(error: ErrorTypesEnum | string) {
      return validateErrorType(error) ? error : ErrorTypesEnum.unknownError
    },
  }
}
