export const bonus = [
  {
    issueId: 6,
    bonusPercentage: 8.75,
  },
  {
    issueId: 15,
    bonusPercentage: 15,
  },
]
