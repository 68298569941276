/* tslint:disable */
/* eslint-disable */
/**
 * Bonds API
 * Microservice backend for bond management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Answer,
  CreateForm,
  DetailQuestion,
  Question,
  RetrieveForm,
} from '../models/index';
import {
    AnswerFromJSON,
    AnswerToJSON,
    CreateFormFromJSON,
    CreateFormToJSON,
    DetailQuestionFromJSON,
    DetailQuestionToJSON,
    QuestionFromJSON,
    QuestionToJSON,
    RetrieveFormFromJSON,
    RetrieveFormToJSON,
} from '../models/index';

export interface AskDetailFormRequest {
    token: string;
    detailQuestion: DetailQuestion;
}

export interface AskFormRequest {
    question: Question;
}

export interface CreateFormRequest {
    createForm: CreateForm;
    utmCampaign?: string;
    utmMedium?: string;
    utmSource?: string;
}

export interface GetFormRequest {
    token: string;
    rawData?: boolean;
}

export interface SubmitFormRequest {
    token: string;
    createForm: CreateForm;
}

/**
 * 
 */
export class FormsApi extends runtime.BaseAPI {

    /**
     */
    async askDetailFormRaw(requestParameters: AskDetailFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Answer>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling askDetailForm.');
        }

        if (requestParameters.detailQuestion === null || requestParameters.detailQuestion === undefined) {
            throw new runtime.RequiredError('detailQuestion','Required parameter requestParameters.detailQuestion was null or undefined when calling askDetailForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/forms/{token}/question/`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DetailQuestionToJSON(requestParameters.detailQuestion),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnswerFromJSON(jsonValue));
    }

    /**
     */
    async askDetailForm(requestParameters: AskDetailFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Answer> {
        const response = await this.askDetailFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async askFormRaw(requestParameters: AskFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Answer>> {
        if (requestParameters.question === null || requestParameters.question === undefined) {
            throw new runtime.RequiredError('question','Required parameter requestParameters.question was null or undefined when calling askForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/forms/question/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionToJSON(requestParameters.question),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnswerFromJSON(jsonValue));
    }

    /**
     */
    async askForm(requestParameters: AskFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Answer> {
        const response = await this.askFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createFormRaw(requestParameters: CreateFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateForm>> {
        if (requestParameters.createForm === null || requestParameters.createForm === undefined) {
            throw new runtime.RequiredError('createForm','Required parameter requestParameters.createForm was null or undefined when calling createForm.');
        }

        const queryParameters: any = {};

        if (requestParameters.utmCampaign !== undefined) {
            queryParameters['utm_campaign'] = requestParameters.utmCampaign;
        }

        if (requestParameters.utmMedium !== undefined) {
            queryParameters['utm_medium'] = requestParameters.utmMedium;
        }

        if (requestParameters.utmSource !== undefined) {
            queryParameters['utm_source'] = requestParameters.utmSource;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/forms/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFormToJSON(requestParameters.createForm),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateFormFromJSON(jsonValue));
    }

    /**
     */
    async createForm(requestParameters: CreateFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateForm> {
        const response = await this.createFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Query parameters: @raw_data - when we want raw form data, else we get structured data
     */
    async getFormRaw(requestParameters: GetFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RetrieveForm>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getForm.');
        }

        const queryParameters: any = {};

        if (requestParameters.rawData !== undefined) {
            queryParameters['raw_data'] = requestParameters.rawData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/forms/{token}/`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RetrieveFormFromJSON(jsonValue));
    }

    /**
     * Query parameters: @raw_data - when we want raw form data, else we get structured data
     */
    async getForm(requestParameters: GetFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RetrieveForm> {
        const response = await this.getFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async submitFormRaw(requestParameters: SubmitFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateForm>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling submitForm.');
        }

        if (requestParameters.createForm === null || requestParameters.createForm === undefined) {
            throw new runtime.RequiredError('createForm','Required parameter requestParameters.createForm was null or undefined when calling submitForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/forms/{token}/submit/`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFormToJSON(requestParameters.createForm),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateFormFromJSON(jsonValue));
    }

    /**
     */
    async submitForm(requestParameters: SubmitFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateForm> {
        const response = await this.submitFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
