import { defineNuxtPlugin, useRuntimeConfig } from '#app'

declare const APP_NAME: string
declare const APP_VERSION: string

export default defineNuxtPlugin({
  name: 'Sentry',
  parallel: true,

  async setup({ vueApp }) {
    const Sentry = (await import('@sentry/vue'))

    const config = useRuntimeConfig()

    if (!config.app.sentryDSN) {
      // eslint-disable-next-line no-console
      console.warn('[Sentry]: Missing DSN config.')
      return
    }

    const router = useRouter()

    Sentry.init({
      app: vueApp,
      dsn: config.app.sentryDSN,
      environment: config.app.environment,
      release: `${APP_NAME}@${APP_VERSION}`,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 0.2,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
    })
  },
})
