<script setup lang="ts">
import { useIssue } from '~/composables/issue/useIssue'

const route = useRoute()

if (String(route.query.token)?.endsWith('.')) {
  await navigateTo({ ...route, query: { ...route.query, token: String(route.query.token)?.replace(/\.$/, '') } })
}

const { error } = await useAsyncData(async app => useApp().init(document?.referrer || app?.ssrContext?.event?.node.req.headers.referer))

if (error.value) {
  showError(error.value)
}

const router = useRouter()
const { t } = useI18n()
const { issue } = useIssue()

setHead(router.currentRoute.value.name as string)
router.afterEach(({ name }, _, failure) => {
  if (!failure)
    return
  setHead(name as string)
})

function setHead(routeName: string) {
  useHead({
    title: `${t(`common.head.${routeName as string}.title`)} | ${issue.value?.company?.name}`,
    link: [
      { rel: 'preload', type: 'image/svg+xml', href: '/sprite.svg' },
    ],
  })
}

// This event will be triggered when "Failed to fetch dynamically imported module" occured.
// https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', (event: any) => {
  event.preventDefault()
  window.location.reload()
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage class="flex-1" />
  </NuxtLayout>
</template>
