/* tslint:disable */
/* eslint-disable */
/**
 * Bonds API
 * Microservice backend for bond management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RetrieveForm
 */
export interface RetrieveForm {
    /**
     * 
     * @type {string}
     * @memberof RetrieveForm
     */
    readonly data: string;
    /**
     * 
     * @type {Date}
     * @memberof RetrieveForm
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof RetrieveForm
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof RetrieveForm
     */
    readonly token: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveForm
     */
    readonly type: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveForm
     */
    readonly state: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof RetrieveForm
     */
    config?: { [key: string]: any; } | null;
}

/**
 * Check if a given object implements the RetrieveForm interface.
 */
export function instanceOfRetrieveForm(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function RetrieveFormFromJSON(json: any): RetrieveForm {
    return RetrieveFormFromJSONTyped(json, false);
}

export function RetrieveFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetrieveForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': json['data'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'token': json['token'],
        'type': json['type'],
        'state': json['state'],
        'config': !exists(json, 'config') ? undefined : json['config'],
    };
}

export function RetrieveFormToJSON(value?: RetrieveForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': value.config,
    };
}

