/* tslint:disable */
/* eslint-disable */
/**
 * Bonds API
 * Microservice backend for bond management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateForm
 */
export interface CreateForm {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CreateForm
     */
    data?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof CreateForm
     */
    readonly token: string;
    /**
     * 
     * @type {string}
     * @memberof CreateForm
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof CreateForm
     */
    readonly state: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CreateForm
     */
    readonly config: { [key: string]: any; } | null;
}

/**
 * Check if a given object implements the CreateForm interface.
 */
export function instanceOfCreateForm(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "config" in value;

    return isInstance;
}

export function CreateFormFromJSON(json: any): CreateForm {
    return CreateFormFromJSONTyped(json, false);
}

export function CreateFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : json['data'],
        'token': json['token'],
        'type': json['type'],
        'state': json['state'],
        'config': json['config'],
    };
}

export function CreateFormToJSON(value?: CreateForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'type': value.type,
    };
}

