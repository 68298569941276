import { toCamelCase } from '@cfgtech/helpers'
import type { IssueData } from './types'
import { ASK_FORM_TYPE, ASK_TYPE_ISSUE } from '~/constants/askType'
import { FormsApi } from '~/services/generated/client'

export function useIssue() {
  const issue = useState<IssueData | null>('issue', () => null)

  const formApi = new FormsApi(useApiConfiguration())

  return {
    issue,

    async fetchIssue(id: number): Promise<IssueData | null> {
      const answer = await formApi.askForm({
        question: {
          formType: ASK_FORM_TYPE,
          type: ASK_TYPE_ISSUE,
          data: { issue_id: id },
        },
      })

      issue.value = ({
        issue: {
          id,
          ...toCamelCase(answer.data.issue),
          company: {
            ...toCamelCase(answer.data.issue.company),
            address: toCamelCase(answer.data.issue.company.address),
          },
        } as unknown as IssueData,
      }).issue

      return issue.value
    },
  }
}
